import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/runner/_work/designsystem-docs/designsystem-docs/src/templates/MDXPage.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DSExample = makeShortcode("DSExample");
const UUTestReport = makeShortcode("UUTestReport");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Bruk av lister`}</h2>
    <p>{`Lister brukes til å gruppere og organisere informasjon slik at den er lettere å lese. Lister kan også hjelpe med å fremheve innhold som ellers kunne blitt borte i en lengre tekst. Trenger du en overskrift til listen, så husk å bruke riktig semantisk heading-tag, men med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ffe-h4`}</code>{`-klassen.`}</p>
    <h2>{`BulletList`}</h2>
    <p>{`BulletList er en unummert liste, og kan brukes i situasjoner der du ønsker å vise frem informasjon i en liste. For å bruke denne komponenten riktig må du også ta i bruk `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`BulletListItem`}</code>{`-komponenten, som representerer et punkt i listen. `}</p>
    <DSExample name="lists_BulletList" mdxType="DSExample" />
    <UUTestReport reportID="Lists_BulletList" mdxType="UUTestReport" />
    <h2>{`CheckList`}</h2>
    <p>{`CheckList er også en unummert liste som brukes i tilfeller der man ønsker å vise f.eks hva som er inkludert i en tjeneste og ikke. For å få riktig ikon og formatering, kan du bruke `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`CheckListItem`}</code>{`-komponenten til hvert punkt på listen. Ønsker du et X isteden trenger du bare å legge inn `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`isCross`}</code>{` som prop.`}</p>
    <DSExample name="lists_CheckList" mdxType="DSExample" />
    <p>{`Det er også mulig å spre innholdet ut over 2 kolonner der det er plass til det, ved å sende med en `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`columns`}</code>{` prop. `}</p>
    <DSExample name="lists_CheckList_columns" mdxType="DSExample" />
    <UUTestReport reportID="Lists_CheckList" mdxType="UUTestReport" />
    <h2>{`DetailListCard`}</h2>
    <p>{`DetailListCard er en listetype der hvert listeelement inneholder en etikett (label) og en verdi (value). Hele listen vises i et kort. Komponenten består av `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`DetailListCard`}</code>{`, som er container-elementet og selve kortet, i tillegg til `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`DetailListCardItem`}</code>{`, som er hvert listeelement.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`DetailListCardItem`}</code>{` tar inn propsene `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`label`}</code>{` og `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`value`}</code>{`. Sistnevnte tillater et `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`React/JSX`}</code>{`-element eller en `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`string`}</code>{`. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`label`}</code>{` inneholder etiketten til venstre i kortet i et `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`dt`}</code>{`-element, mens `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`value`}</code>{` inneholder verdien til høyre i et `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`dd`}</code>{`-element.`}</p>
    <DSExample name="lists_DetailListCard" mdxType="DSExample" />
    <p>{`Siden det er mulig å sende med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`React/JSX`}</code>{`-element, kan du blant annet bruke andre FFE-komponenter i listen.`}</p>
    <DSExample name="lists_DetailListCard_clickable_rows" mdxType="DSExample" />
    <p>{`For å oppfylle WCAG-krav om minimum størrelse på klikkbare flater vil alle lenker og knapper få en minimum størrelse på `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`24px`}</code>{`, som vist i eksempelet under:`}</p>
    <DSExample name="lists_DetailListCard_minimal_clickable" mdxType="DSExample" />
    <h2>{`DescriptionList`}</h2>
    <p>{`DescriptionList er en liste der du kan sette et «begrep» og en beskrivelse. Et eksempel er oppramsing av personalia, der man har gatenavn som begrep og den faktiske adressen som beskrivelse.
Består av komponentene `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`DescriptionListTerm`}</code>{` og `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`DescriptionListDescription`}</code>{`, som brukes til å få riktig semantisk oppbygging av listen.`}</p>
    <DSExample name="lists_DescriptionList" mdxType="DSExample" />
    <UUTestReport reportID="Lists_DescriptionList" mdxType="UUTestReport" />
    <h3>{`Horizontal`}</h3>
    <p>{`Viser «begrep» og beskrivelse på en linje.`}</p>
    <DSExample name="lists_DescriptionList_horizontal" mdxType="DSExample" />
    <h3>{`Medium og large`}</h3>
    <DSExample name="lists_DescriptionList_mediumlarge" mdxType="DSExample" />
    <h3>{`DescriptionListMultiCol`}</h3>
    <p>{`Det er også mulig å bruke `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`DescriptionListMultiCol`}</code>{` for vise listen over to kolonner.`}</p>
    <DSExample name="lists_DescriptionListMultiCol" mdxType="DSExample" />
    <h2>{`NumberedList`}</h2>
    <p>{`Nummererte lister brukes i tilfeller der du vil informere om ting der rekkefølgen er viktig. Et eksempel er en registreringsflyt, eller bare instruksjoner der det er viktig å gjøre ting i en bestemt rekkefølge. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`NumberedListItem`}</code>{` er komponenten som brukes til selve listeelementene.`}</p>
    <DSExample name="lists_NumberedList" mdxType="DSExample" />
    <UUTestReport reportID="Lists_NumberedList" mdxType="UUTestReport" />
    <h2>{`StylizedNumberedList`}</h2>
    <p>{`En mer stylet versjon av `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`NumberedList`}</code>{`. Bruker `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`StylizedNumberedListItem`}</code>{` på selve listeelementene.`}</p>
    <DSExample name="lists_StylizedNumberedList" mdxType="DSExample" />
    <UUTestReport reportID="Lists_StylizedNumberedList" mdxType="UUTestReport" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      